import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createHead } from '@vueuse/head' // @vueuse/head 가져오기
import { store } from './store'

require('@/assets/css/app.scss')
import 'animate.css'
import './assets/css/tailwind.css'
import 'line-awesome/dist/line-awesome/css/line-awesome.css';

// Vue 앱 생성
const app = createApp(App);

app.use(store)
   .use(router)
   .use(createHead()) // 🔥 `createHead()`를 여기에서 실행!
   .mount('#app');
