import createPersistedState from "vuex-persistedstate";
import { createStore } from 'vuex';

export const store = createStore({
  state: {
    // 다크모드
    isDarkMode: true,
    // 현재 언어 설정
    language: "EN",
    // 현재 가로폭
    widthClass: "max-w-[1440px]"
  },
  getters: {},
  mutations: {
    // 다크모드 토글
    toggleMode(state) {
      state.isDarkMode = !state.isDarkMode;
    },
    // 언어 변경
    setLanguage(state, language) {
      state.language = language;
    }
  },
  actions: {},
  // 로컬스토리지 사용해서 고정
  plugins: [createPersistedState()],
});
