<template>
  <button @click="toggleLanguage()" class="h-[28px] w-[28px] rounded-full flex justify-center items-center icon-language"
    :class="isDarkMode || $route.path === '/' ? 'hover:bg-gray-700' : ' hover:bg-gray-200'"
    :style="isDarkMode || $route.path === '/' ? 'stroke: #fff' : 'stroke: #000'">

    <!-- 한글 아이콘 -->
    <div v-if="this.$store.state.language !== 'KO'">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" class="ico_korean">
        <g fill="none" fill-rule="evenodd">
          <g stroke-width="1.5">
            <path
              d="M19.353 9.914c0 5.213-4.226 9.438-9.438 9.438-5.213 0-9.438-4.225-9.438-9.438C.477 4.702 4.702.477 9.915.477c5.212 0 9.438 4.225 9.438 9.437z"
              stroke=""></path>
            <path
              d="M19.205 11.52c-.451-2.769-2.357-4.129-4.643-4.227-2.434-.023-3.491 1.71-4.267 2.615l-.594.69c-1.361 1.238-2.384 1.633-3.876 1.588C2.897 12.153.888 9.456.888 7.224"
              stroke=""></path>
          </g>
        </g>
      </svg>
    </div>

    <!-- 영어 아이콘 -->
    <div v-else>
      <svg data-v-8fe6a5a6="" data-v-61d9a9f1="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
        class="ico_language">
        <g data-v-8fe6a5a6="" fill="none" fill-rule="evenodd">
          <g data-v-8fe6a5a6="" stroke-width="1.35">
            <path data-v-8fe6a5a6=""
              d="M19.353 9.914c0 5.213-4.226 9.438-9.438 9.438-5.213 0-9.438-4.225-9.438-9.438C.477 4.702 4.702.477 9.915.477c5.212 0 9.438 4.225 9.438 9.437z"
              transform="translate(-1402 -23) translate(1358 23) translate(44) translate(2 2)"></path>
            <path data-v-8fe6a5a6="" stroke-linejoin="round"
              d="M13.662 9.914c0 5.213-3.748 9.438-3.748 9.438s-3.747-4.225-3.747-9.438c0-5.212 3.747-9.437 3.747-9.437s3.748 4.225 3.748 9.437z"
              transform="translate(-1402 -23) translate(1358 23) translate(44) translate(2 2)"></path>
            <path data-v-8fe6a5a6="" d="M.876 7.018L18.952 7.018M.876 12.811L18.952 12.811"
              transform="translate(-1402 -23) translate(1358 23) translate(44) translate(2 2)"></path>
          </g>
        </g>
      </svg>
    </div>
  </button>
</template>

<script>
export default {
  name: 'ToggleLanguage',
  computed: {
    isDarkMode() {
      return this.$store.state.isDarkMode;
    },
  },
  methods: {
    toggleLanguage(lang) {
      const currentLanguage = this.$store.state.language;
      const newLanguage = lang ? lang : (currentLanguage === "KO" ? "EN" : "KO");
      this.$store.commit("setLanguage", newLanguage);
    },
  }
}
</script>

<style lang="scss">
.icon-language svg {
  height: 20px;
}
.ico_korean {
  position: relative;
  top: 2px;
  left: 2px;
  margin: 0;
  padding: 0;
}
</style>