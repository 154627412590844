<template>
  <div :class="{ 'dark': store.state.isDarkMode }">
    <div class="w-full min-h-screen bg-gray-50 dark:bg-black text-black dark:text-white transition-all duration-300">
      <component :is="layoutComponent">
        <router-view :key="route.fullPath" />
      </component>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex' // Vuex store 사용
import { useHead } from '@vueuse/head'
import { useRoute } from 'vue-router'

const store = useStore() // Vuex store 가져오기
const route = useRoute() // 현재 라우트 가져오기

// 동적 레이아웃 설정
const layoutComponent = computed(() => route.meta.layout || 'div')

// 🔥 `computed()`를 활용하여 안전하게 `useHead()` 호출
useHead(computed(() => ({
  title: route.meta.title || 'KUnicorn Investment', // 기본값 설정하여 오류 방지
  meta: route.meta.metaTags || [] // 메타 태그가 없으면 빈 배열로 처리
})))
</script>
