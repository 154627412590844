import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDfqLSH0efuF8ZwxS-iJbj3RGZuGWQYzRc",
  authDomain: "ku-web-v3.firebaseapp.com",
  projectId: "ku-web-v3",
  storageBucket: "ku-web-v3.firebasestorage.app",
  messagingSenderId: "1028641358801",
  appId: "1:1028641358801:web:4efbf17c867e1305e996f0",
};

// Firebase 앱 초기화
const app = initializeApp(firebaseConfig);

// Firebase 인증 및 Firestore 초기화
export const auth = getAuth(app);
const db = getFirestore(app);

// Firestore 컬렉션 참조
export const newsCollection = collection(db, "news");
export const menuCollection = collection(db, "menu");
export const mainCollection = collection(db, "main");

// 현재 사용자 가져오기
export function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        unsubscribe(); // 구독 해제
        resolve(user);
      },
      reject
    );
  });
}

// 로그인 함수
export async function login(email, password) {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (error) {
    console.error("Login error:", error.message);
    throw error;
  }
}

// 로그아웃 함수
export async function logout() {
  try {
    await signOut(auth);
  } catch (error) {
    console.error("Logout error:", error.message);
    throw error;
  }
}
