import { createRouter, createWebHistory } from 'vue-router';
import { getCurrentUser } from '@/firebase';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

// 레이아웃
import WebsiteLayout from '@/layouts/WebsiteLayout.vue';
import AdminLayout from '@/layouts/AdminLayout.vue';
import BlankLayout from '@/layouts/BlankLayout.vue';

// Firestore 초기화
const db = getFirestore();

// Firestore 데이터를 캐싱할 변수
let menuCache = null;

// 기본 라우트 설정
const routes = [
  {
    path: '/',
    name: 'UserHomePage',
    component: () =>
      import(/* webpackChunkName: "UserHomePage" */ '../views/user/HomePage.vue'),
    meta: {
      layout: WebsiteLayout,
      metaTags: [
        { property: 'og:title', content: 'Home - KUnicorn Investment' },
        { property: 'og:description', content: 'Trusting Pre-Unicorn Startups' },
        { property: 'og:image', content: 'https://koreaunicorn.com/ogimage.png' },
        { property: 'og:url', content: 'https://koreaunicorn.com/' },
        { name: 'twitter:card', content: 'Kunicorn Investment' },
        { name: 'twitter:title', content: 'Home - KUnicorn Investment' },
        { name: 'twitter:image', content: 'https://koreaunicorn.com/ogimage.png' }
      ]
    },
  },
  {
    path: '/:id',
    name: 'UserMenuPage',
    component: () =>
      import(/* webpackChunkName: "UserMenuPage" */ '../views/user/MenuPage.vue'),
    meta: {
      layout: WebsiteLayout,
      metaTags: [
        { property: 'og:description', content: 'Trusting Pre-Unicorn Startups' },
        { property: 'og:image', content: 'https://koreaunicorn.com/ogimage.png' },
        { property: 'og:url', content: 'https://koreaunicorn.com/' },
        { name: 'twitter:card', content: 'Kunicorn Investment' },
        { name: 'twitter:title', content: 'Home - KUnicorn Investment' },
        { name: 'twitter:image', content: 'https://koreaunicorn.com/ogimage.png' }
      ]
    },
    props: true,
  },
  {
    path: '/login',
    name: 'UserLoginPage',
    component: () =>
      import(/* webpackChunkName: "UserLoginPage" */ '../views/user/LoginPage.vue'),
    meta: {
      layout: BlankLayout,
      guestOnly: true,
      metaTags: [
        { property: 'og:title', content: 'Login - KUnicorn Investment' },
        { property: 'og:description', content: 'Trusting Pre-Unicorn Startups' },
        { property: 'og:image', content: 'https://koreaunicorn.com/ogimage.png' },
        { property: 'og:url', content: 'https://koreaunicorn.com/' },
        { name: 'twitter:card', content: 'Kunicorn Investment' },
        { name: 'twitter:title', content: 'Home - KUnicorn Investment' },
        { name: 'twitter:image', content: 'https://koreaunicorn.com/ogimage.png' }
      ]
    },
  },
  {
    path: '/signup',
    name: 'UserSignupPage',
    component: () =>
      import(/* webpackChunkName: "UserSignupPage" */ '../views/user/SignupPage.vue'),
    meta: {
      layout: BlankLayout,
      guestOnly: true,
      metaTags: [
        { property: 'og:title', content: 'Signup - KUnicorn Investment' },
        { property: 'og:description', content: 'Trusting Pre-Unicorn Startups' },
        { property: 'og:image', content: 'https://koreaunicorn.com/ogimage.png' },
        { property: 'og:url', content: 'https://koreaunicorn.com/' },
        { name: 'twitter:card', content: 'Kunicorn Investment' },
        { name: 'twitter:title', content: 'Home - KUnicorn Investment' },
        { name: 'twitter:image', content: 'https://koreaunicorn.com/ogimage.png' }
      ]
    },
  },

  // 관리자 메뉴
  { path: '/admin', redirect: '/admin/menu' },
  {
    path: '/admin/user',
    name: 'AdminUserSetting',
    component: () => import('../views/admin/UserSetting.vue'),
    meta: {
      layout: AdminLayout,
      requiresAuth: true
    },
  },
  {
    path: '/admin/menu',
    name: 'AdminSiteMenu',
    component: () => import('../views/admin/MenuSetting.vue'),
    meta: {
      layout: AdminLayout,
      requiresAuth: true
    },
  },
  {
    path: '/admin/block',
    name: 'AdminBlockList',
    component: () => import('../views/block/BlockList.vue'),
    meta: {
      layout: AdminLayout,
      requiresAuth: true
    },
  },
  {
    path: '/admin/block/add',
    name: 'AdminBlockAdd',
    component: () => import('../views/block/BlockEdit.vue'),
    meta: {
      layout: AdminLayout,
      requiresAuth: true
    },
  },
  {
    path: '/admin/block/edit/:id',
    name: 'AdminBlockEdit',
    component: () => import('../views/block/BlockEdit.vue'),
    meta: {
      layout: AdminLayout,
      requiresAuth: true
    },
  },
  {
    path: '/admin/block/:id',
    name: 'AdminBlockView',
    component: () => import('../views/block/BlockView.vue'),
    meta: {
      layout: AdminLayout,
      requiresAuth: true
    },
  },
  {
    path: '/board/List',
    name: 'BoardList',
    component: () =>
      import(/* webpackChunkName: "BoardList" */ '../views/board/BoardList.vue'),
    meta: {
      layout: BlankLayout,
      requiresAuth: true,
      metaTags: [
        { property: 'og:title', content: 'Board List - KUnicorn Investment' },
        { property: 'og:description', content: 'Trusting Pre-Unicorn Startups' },
        { property: 'og:image', content: 'https://koreaunicorn.com/ogimage.png' },
        { property: 'og:url', content: 'https://koreaunicorn.com/' },
        { name: 'twitter:card', content: 'Kunicorn Investment' },
        { name: 'twitter:title', content: 'Home - KUnicorn Investment' },
        { name: 'twitter:image', content: 'https://koreaunicorn.com/ogimage.png' }
      ]
    },
  },
  {
    path: '/board/form',
    name: 'BoardForm',
    component: () =>
      import(/* webpackChunkName: "BoardForm" */ '../views/board/BoardForm.vue'),
    meta: {
      layout: BlankLayout,
      requiresAuth: true,
      metaTags: [
        { property: 'og:title', content: 'Board Form - KUnicorn Investment' },
        { property: 'og:description', content: 'Trusting Pre-Unicorn Startups' },
        { property: 'og:image', content: 'https://koreaunicorn.com/ogimage.png' },
        { property: 'og:url', content: 'https://koreaunicorn.com/' },
        { name: 'twitter:card', content: 'Kunicorn Investment' },
        { name: 'twitter:title', content: 'Home - KUnicorn Investment' },
        { name: 'twitter:image', content: 'https://koreaunicorn.com/ogimage.png' }
      ]
    },
  },
  {
    path: '/board/form/:id',
    name: 'BoardFormID',
    props: true,
    component: () =>
      import(/* webpackChunkName: "BoardForm" */ '../views/board/BoardForm.vue'),
    meta: {
      layout: BlankLayout,
      requiresAuth: true,
      metaTags: [
        { property: 'og:title', content: 'Board Form - KUnicorn Investment' },
        { property: 'og:description', content: 'Trusting Pre-Unicorn Startups' },
        { property: 'og:image', content: 'https://koreaunicorn.com/ogimage.png' },
        { property: 'og:url', content: 'https://koreaunicorn.com/' },
        { name: 'twitter:card', content: 'Kunicorn Investment' },
        { name: 'twitter:title', content: 'Home - KUnicorn Investment' },
        { name: 'twitter:image', content: 'https://koreaunicorn.com/ogimage.png' }
      ]
    },
  },
  {
    path: '/board/detail/:id',
    name: 'BoardDetailID',
    props: true,
    component: () =>
      import(/* webpackChunkName: "BoardDetail" */ '../views/board/BoardDetail.vue'),
    meta: {
      layout: BlankLayout,
      requiresAuth: true,
      metaTags: [
        { property: 'og:title', content: 'Board Detail - KUnicorn Investment' },
        { property: 'og:description', content: 'Trusting Pre-Unicorn Startups' },
        { property: 'og:image', content: 'https://koreaunicorn.com/ogimage.png' },
        { property: 'og:url', content: 'https://koreaunicorn.com/' },
        { name: 'twitter:card', content: 'Kunicorn Investment' },
        { name: 'twitter:title', content: 'Home - KUnicorn Investment' },
        { name: 'twitter:image', content: 'https://koreaunicorn.com/ogimage.png' }
      ]
    },
  },
  {
    path: '/admin/:id',
    name: 'AdminMenuPage',
    component: () =>
      import(/* webpackChunkName: "AdminMenuPage" */ '../views/admin/MenuPage.vue'),
    meta: {
      layout: AdminLayout,
      requiresAuth: true,
      metaTags: [
        { property: 'og:title', content: 'Admin Home - KUnicorn Investment' },
        { property: 'og:description', content: 'Trusting Pre-Unicorn Startups' },
        { property: 'og:image', content: 'https://koreaunicorn.com/ogimage.png' },
        { property: 'og:url', content: 'https://koreaunicorn.com/' },
        { name: 'twitter:card', content: 'Kunicorn Investment' },
        { name: 'twitter:title', content: 'Home - KUnicorn Investment' },
        { name: 'twitter:image', content: 'https://koreaunicorn.com/ogimage.png' }
      ]
    },
    props: true,
  },
];

// Firestore에서 메뉴 데이터를 가져와 캐싱하는 함수
async function fetchMenuRoutes() {
  if (menuCache) return menuCache; // 기존 캐시 재사용
  const menuRoutes = [];
  const menuData = {};

  try {
    const menuCollection = collection(db, 'menu');
    const menuSnapshot = await getDocs(menuCollection);

    menuSnapshot.forEach((doc) => {
      const menuItem = doc.data();
      menuData[menuItem.href] = menuItem; // 메뉴 데이터를 캐싱

      // Website 경로 추가
      menuRoutes.push({
        path: `/${menuItem.href}`,
        name: `${menuItem.name_en || menuItem.name_ko}_website`,
        component: () =>
          import(/* webpackChunkName: "UserMenuPage" */ '../views/user/MenuPage.vue'),
        meta: { layout: WebsiteLayout, title: `${menuItem.name_en} - KUnicorn Investment` },
        props: { menuData: menuItem },
      });

      // Admin 경로 추가
      menuRoutes.push({
        path: `/admin/${menuItem.href}`,
        name: menuItem.name_en || menuItem.name_ko,
        component: () =>
          import(/* webpackChunkName: "AdminMenuPage" */ '../views/admin/MenuPage.vue'),
        meta: { layout: AdminLayout, title: `${menuItem.name_en} - KUnicorn Investment`, requiresAuth: true },
        props: { menuData: menuItem },
      });
    });

    menuCache = { routes: menuRoutes, data: menuData }; // Firestore 데이터 캐싱
  } catch (error) {
    console.error('Error fetching menu routes:', error);
  }

  return menuCache;
}

// 라우터 생성
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0, behavior: 'auto' };
  },
});

// 네비게이션 가드
router.beforeEach(async (to) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const guestOnly = to.matched.some((record) => record.meta.guestOnly);
  const currentUser = await getCurrentUser();

  if (requiresAuth && !currentUser) {
    return '/login';
  }
  if (guestOnly && currentUser) {
    return '/';
  }

  // Firestore 데이터가 로드되지 않았다면 동적으로 라우트 추가
  if (!menuCache) {
    const { routes } = await fetchMenuRoutes();
    routes.forEach((route) => router.addRoute(route));
  }

  // Title 설정 (메타 정보가 없을 경우 Firestore에서 찾아 설정)
  if (to.meta.title) {
    document.title = to.meta.title;
  } else if (menuCache && menuCache.data[to.params.id]) {
    document.title = `${menuCache.data[to.params.id].name_en} - KUnicorn Investment`;
  } else {
    document.title = 'KUnicorn Investment'; // 기본 타이틀
  }
});

// Firestore 데이터를 먼저 가져온 후 앱 실행
(async () => {
  await fetchMenuRoutes(); // Firestore 데이터 로드
  router.isReady().then(() => {
    // console.log('Router is ready');
  });
})();

export default router;
