<template>

  <div class="ADMIN_LAYOUT">

    <!-- 헤더 -->
    <header>

      <!-- 헤더 로고 -->
      <div class="ADMIN_LOGO">
        <router-link to="/admin" class="flex items-center font-bold">
          <div class="flex text-md">KUnicorn Investment</div>
          <div class="opacity-50 font-normal mx-2">/</div>
          <div class="flex text-sm font-medium">ADMIN</div>
        </router-link>
      </div>
      
      <!-- 네비게이션 (사이드) -->
      <div class="ADMIN_ACTION">
        <router-link to="/" class="HOME">홈페이지</router-link>
        <ToggleLanguage></ToggleLanguage>
        <ToggleTheme></ToggleTheme>
        <button @click="logout()" class="LOGOUT" :class="isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'">
          <i class="las la-sign-out-alt"></i>
        </button>
      </div>

    </header>

    <!-- 메인 -->
    <main class="ADMIN_MAIN">

      <!-- 사이드 메뉴 -->
      <aside class="ADMIN_ASIDE">
        <ul>
          <li class="LABEL">사이트</li>
          <li><router-link to="/admin/menu" activeClass="ACTIVE">메뉴 설정</router-link></li>
          <li><router-link to="/admin/user" activeClass="ACTIVE">사용자 관리</router-link></li>
          <li class="LABEL">콘텐츠</li>
          <li v-for="(i, id) in menuList" :key="id">
            <router-link :to="'/admin/' + i.href" :class="['', isActive('/admin/' + i.href) ? 'ACTIVE' : '']">
              {{ i.name_ko }}
            </router-link>
          </li>
        </ul>
      </aside>

      <!-- 본문 -->
      <div class="ml-[220px] w-full bg-white dark:bg-gray-800 border-l border-gray-200 dark:border-gray-700">
        <slot></slot>
      </div>

    </main>

  </div>

</template>

<script>
import { useRoute } from 'vue-router';
import { menuCollection } from '@/firebase';
import { onSnapshot, query, orderBy } from 'firebase/firestore';
import { getAuth, signOut } from 'firebase/auth';
import ToggleLanguage from '@/components/ToggleLanguage.vue'
import ToggleTheme from '@/components/ToggleTheme.vue'

export default {
  components: {
    ToggleLanguage,
    ToggleTheme,
  },
  data() {
    return {
      menuList: [],
      unsubscribe: null, // Firestore 구독 해제를 위한 변수
    };
  },
  computed: {
    isDarkMode() {
      return this.$store.state.isDarkMode;
    },
    route() {
      return useRoute(); // 현재 경로 가져오기
    },
  },
  methods: {
    async logout() {
      try {
        const confirmLogout = confirm("로그아웃하시겠습니까?");
        if (!confirmLogout) return; // No를 선택하면 함수 종료

        const auth = getAuth();
        await signOut(auth);
        this.currentUser = null;
        alert("정상적으로 로그아웃 되었습니다.");
        this.$router.push("/");
      } catch (error) {
        console.error("Logout error:", error);
      }
    },
    isActive(path) {
      return this.route.path.startsWith(path);
    },
    fetchMenu() {
      try {
        // Firestore 쿼리: order 필드 기준 정렬
        const q = query(menuCollection, orderBy('order'));
        // 실시간 동기화 설정
        this.unsubscribe = onSnapshot(q, (snapshot) => {
          this.menuList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
        });
      } catch (error) {
        console.error('Error fetching menu:', error);
      }
    },
    cleanupSubscription() {
      if (this.unsubscribe) {
        this.unsubscribe();
        this.unsubscribe = null;
      }
    },
  },
  mounted() {
    this.fetchMenu();
  },
  beforeUnmount() {
    this.cleanupSubscription();
  }
};
</script>
