<template>
  <div class="min-h-screen">

    <!-- 헤더 -->
    <header
      class="w-full fixed z-20 transition-all duration-300"
      :class="{
        'bg-gray-50 dark:bg-black -mt-[70px]': isScrolled,
        'bg-transparent h-[70px]': !isScrolled && $route.path === '/',
        'h-[70px]': !isScrolled && $route.path !== '/',
      }"
    >
      <div
        class="SITE-WIDTH w-full px-6 mx-auto block flex justify-between items-center transition-all duration-300"
        :class="{'-mt-[70px]': isScrolled, 'h-[70px]': !isScrolled}">
        <!-- 로고 -->
        <div class="w-[150px]">
          <router-link to="/" class="font-bold">
            <div class="flex">
              <div v-if="$route.path === '/'">
                <img class="h-8 w-auto transition-all duration-300" :src="require('../assets/images/logo-kui-white.png')" alt="" />
              </div>
              <div v-else>
                <img class="h-8 w-auto hidden dark:block transition-all duration-300" :src="require('../assets/images/logo-kui-white.png')" alt="" />
                <img class="h-8 w-auto block dark:hidden transition-all duration-300" :src="require('../assets/images/logo-kui-color.png')" alt="" />
              </div>
            </div>
          </router-link>
        </div>

        <!-- 네비게이션 (센터) -->
        <div class="text-center font-medium hidden lg:block">
          <router-link
            v-for="item in sortedMenuList"
            :key="item.id"
            :to="item.href"
            class="tracking-normal"
            :class="{
              'text-black/[1] dark:text-white/[1] font-extrabold': $route.path === `/${item.href}`,
              'text-md mx-4 uppercase text-black/[.7] dark:text-white/[.7]': true,
              'text-md mx-3 text-black dark:text-white font-semibold': true
            }"
          >
            <span v-if="$route.path === '/'" class="text-white/[0.7]">
              {{ this.$store.state.language === 'KO' ? item.name_ko : item.name_en }}
            </span>
            <span v-else>
              {{ this.$store.state.language === 'KO' ? item.name_ko : item.name_en }}
            </span>
          </router-link>
        </div>

        <!-- 네비게이션 (사이드) -->
        <div class="flex items-center justify-end gap-x-2 w-[150px]">
          <ToggleLanguage></ToggleLanguage>
          <ToggleTheme></ToggleTheme>
          
          <!-- 햄버거 버튼 -->
          <div class="block lg:hidden ml-1">
            <button
              @click="openSidebar"
              class="rounded-lg px-2 bg-whtie hover:bg-gray-300 dark:bg-black text-lg h-[30px] flex items-center justify-center border border-gray-300 dark:border-gray-700 dark:text-white text-black"
            >
              <i class="las la-bars"></i>
            </button>
          </div>

          <!-- 배경 레이어 (클릭하면 사이드바 닫힘) -->
          <div 
            v-if="isOpenSidebarLayer"
            @click="closeSidebar"
            class="fixed inset-0 bg-black dark:bg-white bg-opacity-50 dark:bg-opacity-50 z-40"
          ></div>

          <!-- 사이드바 -->
          <aside
            class="w-72 h-screen bg-white dark:bg-black text-white flex flex-col py-0 px-6 fixed top-0 right-0 z-50 transition-transform transform"
            :class="isOpenSidebarLayer ? 'translate-x-0' : 'translate-x-full'"
          >
            <div class="flex justify-end items-center h-[70px]">
              <button
                @click="closeSidebar"
                class="rounded-lg px-2 bg-whtie hover:bg-gray-300 dark:bg-black text-lg h-[30px] flex items-center justify-center border border-gray-300 dark:border-gray-700 dark:text-white text-black"
              >
                <i class="las la-times"></i>
              </button>
            </div>
            <div class="py-8 border-b border-gray-300 dark:border-gray-600">
              <img class="h-8 w-auto hidden dark:block" :src="require('../assets/images/logo-kui-white.png')" alt="" />
              <img class="h-8 w-auto block dark:hidden" :src="require('../assets/images/logo-kui-color.png')" alt="" />
            </div>
            <nav class="py-8 border-b border-gray-300 dark:border-gray-600">
              <router-link
                v-for="item in sortedMenuList"
                :key="item.id"
                :to="item.href"
                class="text-lg text-black dark:text-white block py-1.5 font-bold"
                @click="closeSidebar"
              >
                {{ this.$store.state.language === 'KO' ? item.name_ko : item.name_en }}
              </router-link>
            </nav>
            <div class="pt-8">
              <div class="font-medium text-black/[.5] dark:text-white/[.5] pt-3 text-xs leading-5 break-keep">
                <div v-html="address"></div>
                <div>Copyright ⓒ 2025 Kunicorn Investment.  All rights are reserved.</div>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </header>

    <div class="min-h-screen">
      <slot></slot>
    </div>

    <!-- 푸터 -->
    <footer :class="{'animate__animated animate__fadeInUp': hasFooterAnimated}"
      v-if="$route.path !== '/'" class="w-full pt-16 pb-32 text-black/[.5] dark:text-white/[.5] opacity-0 transition-all duration-300">
      <div
        class="SITE-WIDTH w-full px-6 mx-auto grid grid-cols-1 md:grid-cols-3 gap-x-8 leading-7">
        
        <!-- 1번째 컬럼 -->
        <div class="mb-3 text-lg font-bold">
          {{ this.$store.state.language === 'KO' ? '케이유니콘 인베스트먼트' : "KUnicorn Invetment" }}
        </div>

        <!-- 2번째 컬럼 -->
        <div class="col-span-2 font-medium text-base break-keep">
          <div class="font-bold">
            <span class="mr-3" v-for="item in sortedMenuList" :key="item.id">
              <router-link :to="item.href" class="hover:text-black/[1] hover:dark:text-white/[1] transition-all duration-300">
              {{ this.$store.state.language === 'KO' ? item.name_ko : item.name_en }}
              </router-link>
            </span>
            <span class="mr-3 hidden sm:inline-block">·</span>
            <span class="inline-block">
              <a href="/admin" target="_blank" class="hover:text-black/[1] hover:dark:text-white/[1] transition-all duration-300">
                {{ this.$store.state.language === 'KO' ? '펀드관리시스템' : "KUI FMS" }}
                <img class="h-[16px] inline" :src="require('@/assets/icon/arrow_outward.svg')" />
              </a>
            </span>
          </div>
          <div class="font-medium text-black/[.5] dark:text-white/[.5] pt-3 text-sm leading-6">
            <div v-html="address"></div>
            <div>Copyright ⓒ 2025 Kunicorn Investment.  All rights are reserved.</div>
          </div>
        </div>

      </div>
    </footer>
  </div>
</template>

<script>
import { getDocs } from 'firebase/firestore';
import { menuCollection } from '@/firebase';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import ToggleLanguage from '@/components/ToggleLanguage.vue'
import ToggleTheme from '@/components/ToggleTheme.vue'

export default {
  components: {
    ToggleLanguage,
    ToggleTheme,
  },
  data() {
    return {
      mobileMenuOpen: false,
      currentUser: null,
      menuList: [],
      isScrolled: false,         // 헤더 스타일 변경용
      isFooterVisible: false,    // 푸터 애니메이션 트리거
      hasFooterAnimated: false,  // 푸터 애니메이션 실행 여부
      isOpenFooterLayer: false,
      isOpenSidebarLayer: false
    };
  },
  computed: {
    address() {
      return this.$store.state.language === 'KO' 
        ? `(06301) 서울 강남구 논현로28길 45 덕윤빌딩 4층, <br class="block md:hidden" />케이유니콘인베스트먼트<br />문의사항은 info@koreaunicorn.com 또는 02-538-0887로 연락해주시기 바랍니다.<br />`
        : `(06301) 4th floor of Deokyoon Building, Nonhyeon-ro 28-gil 45, Gangnam-gu, Seoul, South Korea<br />For more information, please contact info@koreaunicorn.com or (+82) 02-538-0887`;
    },
    sortedMenuList() {
      return [...this.menuList].sort((a, b) => a.order - b.order);
    },
  },
  methods: {
    openSidebar() {
      this.isOpenSidebarLayer = true;
      document.body.style.overflow = "hidden"; // 스크롤 방지
    },
    closeSidebar() {
      this.isOpenSidebarLayer = false;
      document.body.style.overflow = ""; // 스크롤 복원
    },
    async fetchMenu() {
      try {
        const querySnapshot = await getDocs(menuCollection);
        this.menuList = querySnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .sort((a, b) => a.order - b.order);
      } catch (error) {
        console.error('Error fetching menu:', error);
      }
    },
    async logout() {
      try {
        const auth = getAuth();
        await signOut(auth);
        this.currentUser = null;
      } catch (error) {
        console.error('Logout error:', error);
      }
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 10; // 10px 이상 스크롤하면 border-b 활성화 및 높이 축소

      const windowHeight = window.innerHeight;
      const scrolledToBottom = window.scrollY + windowHeight >= document.body.offsetHeight - 10;
      const footerHiddenAgain = window.scrollY + windowHeight < document.body.offsetHeight - 200; // footer가 다시 안 보일 때

      if (scrolledToBottom && !this.hasFooterAnimated) {
        // 애니메이션 실행 및 상태 변경
        this.isFooterVisible = false;
        setTimeout(() => {
          this.isFooterVisible = true;
          this.hasFooterAnimated = true; // 한 번 실행되면 true로 변경하여 반복 실행 방지
        }, 100);
      }

      if (footerHiddenAgain) {
        // 푸터가 다시 화면에서 사라지면 상태 초기화 (애니메이션 재실행 가능)
        this.hasFooterAnimated = false;
      }
    },
  },
  watch: {
    $route() {
      // 라우트 변경 시 애니메이션 초기화
      this.isFooterVisible = false;
      this.hasFooterAnimated = false;
      setTimeout(() => {
        this.isFooterVisible = true;
      }, 100);
    }
  },
  mounted() {
    this.fetchMenu();
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      this.currentUser = user;
    });

    // 스크롤 이벤트 추가
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    // 이벤트 리스너 해제
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>
